<template>
<div class="container padding-container">
	<div class="border-container">
		<div class="info-container">
			<span class="list-title">
				测评名额
				<span class="residue" v-if="residueNumber">{{residueNumber}}</span>
			</span>
			<el-button type="primary" plain @click="handleDialogShow">
				<i class="iconfont icon-recharge_order_management"/> 充值
			</el-button>
		</div>
	</div>
	<div class="border-container">
		<div class="info-container">
			<span class="list-title">充值记录</span>
		</div>
		<div class="table-container">
			<el-table :data="rechargeList" tooltip-effect="dark" style="width: 100%" size="medium"
				:header-cell-style="{height: '40px', background: 'linear-gradient(180deg,rgba(251,252,253,1) 0%,rgba(246,248,250,1) 100%)'}"
				:cell-style="{height: '50px',padding: '5px 0'}" header-align="center">
				<el-table-column label="订单编号" min-width="150" align="center">
					<template slot-scope="scope">
						{{scope.row.sn }}
					</template>
				</el-table-column>
				<el-table-column label="支付金额" min-width="150" align="center">
					<template slot-scope="scope">
						{{scope.row.charge / 100 }}
					</template>
				</el-table-column>
				<el-table-column label="名额数量" min-width="150" align="center">
					<template slot-scope="scope">
						{{scope.row.invitation_count }}
					</template>
				</el-table-column>
				<el-table-column label="支付状态" min-width="150" :show-overflow-tooltip="true" align="center">
					<template slot-scope="scope">
						{{scope.row.status | orderStatusFilter}}
					</template>
				</el-table-column>
				<el-table-column label="创建时间" min-width="155" align="center">
					<template slot-scope="scope">
						{{scope.row.create_time | timeFilter}}
					</template>
				</el-table-column>
				<div class="" slot="empty">
					<no-data></no-data>
				</div>
			</el-table>
		</div>
		<div class="page-container">
			<el-pagination layout="total, sizes, prev, pager, next" background
				@size-change="pageSizeChange"
				@current-change="pageChange"
				:current-page="currentPage"
				:page-sizes="[total, 10, 15, 20, 25]"
				:total="total" :page-size="pageSize">
			</el-pagination>
		</div>
	</div>
	<el-dialog title="充值测试名额" :visible.sync="dialogVisible" @close="handleDialogClose" class="school-record-dialog" width="750px">
		<div class="dialog-content">
			<div class="content-floor">
				<div class="key">名额数量</div>
				<div class="template-list" >
					<div class="template-item" :class="{'item-active': moneyTemplateIndex === index}"
						v-for="(template, index) in MONEY_TEMPLATE" :key="index"
						@click="checkMoneyTemplateIndex(index)">
						<div class="template-block" v-if="template.type === 0">
							<div class="template-line1">
								<input placeholder="购买数量" ref="set-num-input" type="text" v-model="template.num" @change="setNumChange(template.num)">
							</div>
							<div class="template-split"></div>
							<div class="template-line2">
								<span class="size-12 mar-r-3">¥</span>{{template.price / 100}}/<span class="size-12">个</span>
							</div>
						</div>
						<div class="template-block" v-else>
							<div class="template-line1">
								<img src="@/assets/images/quota/head-icon.png"/>
								{{template.num}}
							</div>
							<div class="template-split"></div>
							<div class="template-line2">
								<span class="size-12 mar-r-3">¥</span>{{template.price / 100}}
							</div>
						</div>
						<img class="check-box-blue" src="@/assets/images/quota/check-box-blue.png" height="75" width="78"/>
					</div>
				</div>
			</div>
			<div class="content-floor">
				<div class="key">支付方式</div>
				<div class="template-list">
					<div class="template-item" :class="{'item-active': payTypeIndex === index}"
						v-for="(template, index) in PAY_TYPE" :key="index" @click="checkPayType(index)" >
						<div class="pay-test">{{template.name}}支付</div>
						<img class="check-box-blue" src="@/assets/images/quota/check-box-blue.png" height="75" width="78"/>
					</div>
				</div>
			</div>
			<div class="content-floor" v-if="payTypeIndex !== ''">
				<div class="key"></div>
				<div class="template-list">
					<img class="pay-zhifubao mar-r-3" v-if="payTypeIndex === 0"  src="@/assets/images/zhifubao.png"/>
					<img class="pay-wx mar-r-3" v-else src="@/assets/images/wx.png"/>
					<div class="f-w-500 d-block size-14">
						{{PAY_TYPE[payTypeIndex].name}}扫码，支付<span class="size-20 c-F66330 f-w-500">{{charge / 100}}</span>元
					</div>
				</div>
			</div>
			<div class="content-floor" v-if="payTypeIndex !== ''">
				<div class="key"></div>
				<div class="template-list">
					<div class="qrcode" ref="qrCodeUrl"></div>
				</div>
			</div>
		</div>
	</el-dialog>
</div>
</template>

<script>
import Core from 'core';
import QRCode from 'qrcodejs2'
var qrcode = null
export default {
    components: {
        NoData: () => import('@/components/Empty.vue')
    },
    props: {},
    data() {
        return {
			schoolId: Core.Data.getBelong().id,
            total: 0,
            currentPage: 1,
            pageSize: 10,
            dialogVisible: false,
            multipleSelection: [],
            rechargeList: [],
			MONEY_TEMPLATE: Core.Utils.deepCopy(Core.Const.MONEY_TEMPLATE),
			PAY_TYPE: Core.Const.PAY_TYPE,
            residueNumber: '',
			moneyTemplateIndex: '',
			payTypeIndex: '',
			charge: '',
			payStatusInterval: '',
			creatOrder: '',		// 创建支付订单
			renderProjectTransactionId: '',		// 点击事件的时间
        };
    },
    created() {
        this.getTableList();
        this.getResidueNumber();
    },
    methods: {
		cleanQrCode() {
			let qrCodeUrl = this.$refs.qrCodeUrl
			if (qrCodeUrl) {
				qrCodeUrl.innerHTML = ''
			}
		},
		creatQrCode(content) {
			this.cleanQrCode()
			qrcode = new QRCode(this.$refs.qrCodeUrl, {
				text: content, // 需要转换为二维码的内容
				width: 100,
				height: 100,
				colorDark: '#000000',
				colorLight: '#ffffff',
				correctLevel: QRCode.CorrectLevel.H
			})
		},
		async setNumChange(val) {
			if (val) {
				console.log('val', val)
				let transactionId = new Date().getTime();		// 防止多次点击
				await this.createOrder(0)
				this.checkPayType(0, transactionId)
			}
		},
		async checkMoneyTemplateIndex(index) {
			this.moneyTemplateIndex = index
			if (index !== 0) {
				await this.createOrder(index)
			} else if (index === 0) {
				if (this.MONEY_TEMPLATE[this.moneyTemplateIndex].num) {
					await this.createOrder(index)
				} else {
					this.payTypeIndex = ''
				}
				let ref = this.$refs['set-num-input']
				if (ref.focus) {
					ref.focus();
				} else if (ref[0]){
					ref[0].focus();
				}
				console.log('ref', ref)
			}
		},
		async createOrder(index) {
			let transactionId = new Date().getTime();		// 防止多次点击
			let moneyTemplate = this.MONEY_TEMPLATE[index]
			let num = moneyTemplate.num
			let price = num * 990
			let charge = moneyTemplate.type === 0 ? price : moneyTemplate.price
            // charge = 1 测试用
			this.charge = charge	// 该支付金额
			console.log('this.creatOrder', this.creatOrder)
			if (this.creatOrder) {
				var orderRes = await Core.Api.Order.update(
						this.creatOrder.id,
						price,
						charge,
						num,
				)
			} else {
				var orderRes = await Core.Api.Order.createInvitationOrder(
						this.schoolId,
						price,
						charge,
						num,
				)
			}
			this.creatOrder = orderRes.detail
			this.checkPayType(0, transactionId)
			this.getTableList()
		},
		async checkPayType(index, transactionId = new Date().getTime()) {
			if (this.moneyTemplateIndex === '') return this.$message.warning("请选择名额数量")
			if (this.moneyTemplateIndex === 0 && !this.MONEY_TEMPLATE[this.moneyTemplateIndex].num)  return this.$message.warning("请输入名额数量")
			this.renderProjectTransactionId = transactionId;
			this.payTypeIndex = index
			let payType = this.PAY_TYPE[index].type
			this.getPayStatus(this.creatOrder.id, transactionId)
			let payRes = await Core.Api.Order.pay(
					this.creatOrder.sn,
					payType
			)
			console.log('payRes', payRes)
			if (transactionId !== this.renderProjectTransactionId) return		// 防止多次点击
			this.creatQrCode(payRes.detail.code_url)
		},
		getPayStatus(orderId, transactionId) {
			this.clearPayStatusInterval()
			this.payStatusInterval = setInterval(async () => {
				let detailRes = await Core.Api.Order.detail(orderId)
				console.log('detailRes', detailRes)
				let orderStatus = detailRes.detail.status
				if (transactionId !== this.renderProjectTransactionId) {		// 防止多次点击
					return this.clearPayStatusInterval()
				}
				if (orderStatus === ORDER.STATUS.ORDER_PAY || orderStatus === ORDER.STATUS.ORDER_CLOSE) {	// 支付成功
					this.clearPayStatusInterval()
					this.dialogVisible = false
					this.$message.success("支付成功")
					this.getTableList()
					// let createByOrderRes = await Core.Api.SchoolRecord.createByOrder(orderId)
					// console.log('createByOrderRes', createByOrderRes)
				}
			}, 1000)

		},
		clearPayStatusInterval() {
			if (this.payStatusInterval) {
				clearInterval(this.payStatusInterval)
				this.payStatusInterval = ''
			}
		},
        pageChange(page) {
            this.currentPage = page;
            this.getTableList();
        },
        pageSizeChange(size) {
            this.pageSize = size;
            this.getTableList();
        },
        getTableList() {
            Core.Api.Order.list(
				this.currentPage,
				this.pageSize,
				this.schoolId,
				10,		// const val TYPE_INVITATION = 10
            ).then((res) => {
				console.log('Order.list', res)
				this.total = res.count;
				this.rechargeList = res.list
            });
        },
        getResidueNumber() {
            Core.Api.School.detail(this.schoolId).then((res) => {
                this.residueNumber = res.school.record_invitation_count;
            });
        },
        routerChange(key, q = '') {
            switch (key) {
                case 1:
                    // this.$router.push('');
                    break;
            }
        },
        handleDialogShow() {
            this.dialogVisible = true;
        },
        handleDialogClose() {
			this.creatOrder = ''
			this.moneyTemplateIndex = ''
			this.payTypeIndex = ''
			this.renderProjectTransactionId = ''
			this.clearPayStatusInterval()
            this.dialogVisible = false;
        },
    }
};
</script>
<style lang="scss" scoped>
.container {
    @import '@/common/styles/table.scss';
    .border-container:first-child {
        margin-bottom: 20px;
        .info-container {
            border-bottom: 0;
			.list-title {
				display: flex;
				align-items: center;
			}
        }
    }
    .info-container {
        @include flex(row, space-between, center);
        .residue {
            display: inline-block;
            padding: 0 10px;
            border-radius: 4px;
            margin-left: 30px;
            background-color: rgba(30, 181, 78, .13);
            color: #1EB54E;
            border: 0;
            height: 24px;
            font-size: 14px;
            line-height: 24px;
        }
    }
    .school-record-dialog {
        .dialog-content {
            .content-floor {
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                .key {
                    display: block;
                    width: 120px;
                }
                &:last-child {
                    margin-bottom: 0px;
                }
				.template-list {
					display: flex;
					align-items: center;
					.template-item {
						width: 130px;
						margin-right: 10px;
						border: 1px solid #E5E8EB;
						border-radius: 4px;
						cursor: pointer;
						position: relative;
						&:last-child {
							margin-right: 0;
						}
						&.item-active {
							border: 1px solid #3487EE;
							.check-box-blue {
								display: block;
							}
						}
						.check-box-blue {
							width: 26px;
							height: 25px;
							display: none;
							position: absolute;
							right: 0;
							top: 0;
						}
						.template-block {
							display: flex;
							align-items: center;
							justify-content: center;
							flex-direction: column;
							.template-line1 {
								height: 95px;
								display: flex;
								align-items: center;
								justify-content: center;
								color: #313131;
								font-size: 20px;
								font-weight: 500;
								input {
									width: 80px;
									height: 28px;
									font-size: 20px;
									border: none;
									text-align: center;
								}
								img {
									width: 24px;
									height: 24px;
									margin-right: 3px;
								}
							}
							.template-line2 {
								height: 34px;
								line-height: 34px;
								font-size: 16px;
								color: #F66330;
								font-weight: 500;
							}
							.template-split {
								width: 104px;
								border-bottom: 1px dashed #E3E3E3;
							}
						}
						.pay-test {
							height: 40px;
							display: flex;
							align-items: center;
							justify-content: center;
							font-size: 14px;
							color: #313131;
							font-weight: 400;
						}
					}
				}
            }
        }
    }
	.size-20 {
		font-size: 20px;
	}
	.size-14 {
		font-size: 14px;
	}
	.size-12 {
		font-size: 12px;
	}
	.mar-r-3 {
		margin-right: 3px;
	}
	.c-F66330 {
		color: #F66330;
	}
	.f-w-400 {
		font-weight: 400;
	}
	.f-w-500 {
		font-weight: 500;
	}
	.f-w-600 {
		font-weight: 600;
	}
	.d-block {
		display: block !important;
	}
	.pay-wx {
		width: 20px;
		height: 18px;
	}
	.pay-zhifubao {
		width: 20px;
		height: 20px;
	}
}
</style>